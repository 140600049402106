import React, {useEffect, useState, useCallback} from 'react';
import PhoneCountryDropdownProps, { Country } from './props';
import style from './style.module.scss';
import classNames from '../../../utils/classNames';
import chevron_up from '../../../icons/chevron-up2.svg';
import chevron_down from '../../../icons/chevron-down2.svg';
import Button from '../../private/Button';
import Input from '../../private/Input';

function PhoneCountryDropdown(props: PhoneCountryDropdownProps) {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(props.countrySelected);
  const [dropdownWidth, setDropdownWidth] = useState<string | number>('');
  const prefixLabel = props?.prefixLabel || 'Phone prefix';

  const startWidth = useCallback(() => {
      const w = props.getWidth?.(); 
      setDropdownWidth(w || '');
    
  }, [props.getWidth]);

  useEffect(() => {
    if(props.getWidth){
      startWidth();
    }
    
  }, [dropdownOpen, startWidth]);


  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCountrySelect = (country: Country) => {
    setSelectedCountry(country);
    setDropdownOpen(false);
    setSearch('');
    props.onChangePrefix?.(country.prefix);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current) {
        return;
      }
      if (!dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    }
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);



  const dropdownButton = () => {
    return {
      onClick: handleDropdownClick,
      classNames: {
        icon: true,
      },
      Icon: {
        iconSrc: dropdownOpen ? chevron_up : chevron_down,
        alt: dropdownOpen ? 'close dropdown' : 'open dropdown',
      },
      Label: undefined,
      e2e: 'dropdownButton',
    };
  };

  const inputSearchProps = (props: PhoneCountryDropdownProps) => {
    return {
      classNames: {
        text: true,
        search: true,
      },
      type: 'text',
      value: search,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setSearch(e.target.value),
      Label: {
        text: props.searchText || 'Search for country',
        hidden: true,
      },
      id: 'search',
      name: 'search',
      placeholder: props.searchText || 'Search for country',
    };
  };
  const dropdown = () => {
    const filteredCountries = props.countries
      .filter((country) =>
        country.name.toLowerCase().startsWith(search.toLowerCase())
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    if (filteredCountries.length === 0) {
      return <div key='no-results' className={classNames('no-results', style)}>{props.noResultsText || 'No results found'}</div>;
    }

    return filteredCountries.map((country) => (
      <div
        key={country.code}
        onClick={() => handleCountrySelect(country)}
        className={classNames('country-dropdown-item', style)}
      >
        <img
          className={classNames('dropdown-flag', style)}
          src={country.src}
          alt={country.name}
          loading="lazy"
        />
        <span
          className={classNames('country-name', style)}
          dangerouslySetInnerHTML={{
            __html: country.name.replace(
              new RegExp(`^(${search})`, 'i'),
              '<strong>$1</strong>'
            ),
          }}
        />
        <span className={classNames('country-prefix', style)}>{`(${country.prefix})`}</span>
      </div>
    ));
  };

  return (
    <div
      className={classNames('phoneCountryDropdown', style)}
      data-e2e='phoneCountryDropdown'
      ref={dropdownRef}
    >
      <div
        className={classNames('selected-country', style)}
        data-e2e='selected-country'
        onClick={() => handleDropdownClick()}
      >
        <img
          className={classNames('selected-country-flag', style)}
          src={selectedCountry?.src}
          alt={selectedCountry?.name}
          loading="lazy"
        />
        <span className={classNames('selected-country-prefix', style)}>
          <input name="prefix" className={classNames('selected-country-prefix', style)} value={selectedCountry?.prefix} readOnly={true} aria-label={prefixLabel} data-e2e='input-country-prefix'/>
        </span>
        <Button {...dropdownButton()} />
      </div>
      {dropdownOpen && (
        <div
          className={classNames('country-search-dropdown', style)}
          data-e2e='country-search-dropdown'
          style={{width: dropdownWidth}}
        >
          <Input {...inputSearchProps(props)} />
          <div className={classNames('country-dropdown', style)}>
            {dropdown()}
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(PhoneCountryDropdown);
