import React, { useMemo } from 'react';
import SellerNameProps from "./props";
import style from "./style.module.scss";
import classNames from '../../../utils/classNames';
import config from './config.json';

import Title from '../../private/Title/Title';
import TitleProps from '../../private/Title/props';
import Link from '../../private/Link/Link';
import LinkProps from '../../private/Link/props';
import Content from '../../private/Content/Content';
import ContentProps from '../../private/Content/props';
import PhoneDisplayer from '../../private/PhoneDisplayer';
import PhoneDisplayerProps from '../../private/PhoneDisplayer/props';
import Button from '../../private/Button';
import close from '../../../icons/close.svg';


function SellerName(props: SellerNameProps) {

  const buttonProps = useMemo(() => {
    return {
      onClick: props.onClickCloseButton,
      classNames: config.Button.classNames,
      Icon: {
        svg: close,
        alt: 'close',
        classNames: config.Button.Icon.classNames
      },
      e2e: props.closeButtonE2e
    };
  }, [props]);


  return (
    <div className={classNames('sellerName', style, {...props.classNames})} data-e2e='sellerName'>
      {!!props.listedByText && (
        <div className={classNames('listedByText', style)}>
          {props.listedByText}
        </div>
      )}
      <div>
        {props.name && <Title {...titleProps(props)} />}
        {props.showCloseButton && <Button {...buttonProps} />}
      </div>
      {props.address &&
        (props.linkContactInfo ? (
          <Link {...linkContactInfoProps(props)} />
        ) : (
          <address>{props.address}</address>
        ))}
      {!props.hidePhone && (props.displayPhoneMessage ?
        <PhoneDisplayer {...phoneDisplayerProps(props)} />
        :
        <div className={props.onClickPhoneNumber ? classNames('disable-media-queries', style) : ''}>
          <span>
            <Link {...linkProps(props)}></Link>
          </span>
          {!props.onClickPhoneNumber && <Content {...contentProps(props)} />}
        </div>
      )}
    </div>
  );
}

const linkContactInfoProps = (props: SellerNameProps): LinkProps => {
  return {
    text: props.address,
    url: props.addressLink,
    target: '_blank'
  };
}

const titleProps = (props: SellerNameProps): TitleProps => {
  return {
    text: props.name,
    classNames: props.classNames?.sellerNameV2 ? { 'title-12': true } : undefined
  };
};

const phoneDisplayerProps = (props: SellerNameProps): PhoneDisplayerProps => {
  return {
    phoneNumber: props.phoneNumber,
    phoneText: props.phoneText || 'View phone number',
    onClickPhoneMessage: props.onClickPhoneMessage
  }
};

const linkProps = (props: SellerNameProps): LinkProps => {
  return {
    onClick: props.onClickPhoneNumber || props.onClick,
    url: `tel:${props.phoneNumber}`,
    title: `Call ${props.name} now`,
    text: `${props.phoneNumber}`
  }
};

const contentProps = (props: SellerNameProps): ContentProps => {
  return {
    text: props.phoneNumber,
    classNames: config.Content.classNames
  };
};

export default React.memo(SellerName);